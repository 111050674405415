/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"
import SocialLinks from "./social-links"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 70, height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `block`,
        marginBottom: rhythm(2.5),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(3 / 4),
          marginBottom: rhythm(3 / 4),
          minWidth: 50,
          borderRadius: `100%`,
          float: `left`
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>Hey there! I'm Tammy Ritterskamp, a front end web developer living and working in St. Louis, MO.</p> 
      
      <p>I've been building the front end of websites since IE6 png hacks were a thing (more than 10 years) using HTML, CSS and JavaScript. Currently I'm working in eCommerce on a large multi-brand platform and design system.</p>
      
      <p>In my spare time, I like to make silly side projects to try out new coding techniques. You can see some of those examples on <Link to="https://codepen.io/tritterskamp" target="_blank">CodePen</Link>.</p>

      <p>Besides all that, I also love gardening, cooking, hiking with my dogs, and volunteering time in animal welfare.</p>

      <p>Occasionally, I write about the things I'm creating, learning and tinkering with <Link to="/blog">here</Link>.</p>

      <p>And if you want to connect, I can be found in these social places: <SocialLinks /></p>
    </div>
  )
}

export default Bio
